import { ClaimsPermissionChecker, GroupSubject, OrganizationSubject, ProfileSubject } from '@bp/pim-auth-permissions';
import {
  AdminRoles,
  ChildProfilesClaim,
  GroupRoles,
  OmniOrganizationClaim,
  OrganizationClaim,
  OtherProfilesClaim,
  ProfileClaim,
  ProfileRoles,
  RolesClaim,
  UserClaim,
} from '@bp/pim-auth-constants';
import dayjs from 'dayjs';

export interface ProfileRoleSubject extends ProfileSubject {
  role: ProfileRoles;
}

export interface AuthorizationClaims {
  profile: ProfileClaim;
  organization: OrganizationClaim;
  user?: UserClaim;
  roles: RolesClaim;
  otherProfiles?: OtherProfilesClaim;
  childProfiles?: ChildProfilesClaim;
  omniOrganization: OmniOrganizationClaim;
}

export interface WorkmaterialSubject {
  workmaterialUuid: string;
  owner: ProfileSubject;
}

export interface CalendarEventSubject {
  organizationUuid: string;
  ownerProfileUuid: string;
}

export interface FileEntrySubject {
  organization: OrganizationSubject;
  owner: ProfileSubject;
}

export interface ZoomMeetingSubject {
  organizationUuid: string;
  profileUuid: string;
}

export interface AssignmentSubject {
  uuid: string;
  ownerUuid: string;
  groupUuid: string;
  organization: OrganizationSubject;
}

export class BpPermissionChecker extends ClaimsPermissionChecker {
  private allowStudentDirectMessages: boolean = true;
  private otherProfiles?: OtherProfilesClaim;
  private childProfiles?: ChildProfilesClaim;

  constructor(claims: AuthorizationClaims, allowStudentDirectMessages: boolean = true) {
    super(claims.profile, claims.organization, claims.roles, claims.user, claims.omniOrganization);
    this.otherProfiles = claims.otherProfiles;
    this.childProfiles = claims.childProfiles;
    this.allowStudentDirectMessages = allowStudentDirectMessages;
  }

  // start PROFILE
  canChangeProfile() {
    return (this.otherProfiles ?? []).length > 0;
  }

  canImpersonateChildProfile() {
    return (this.childProfiles ?? []).length > 0;
  }

  // end PROFILE

  // start LICENSE & COLLABORATION
  canCreateCollaboration(_organization: OrganizationSubject) {
    return (
      // disable this for now
      // this.hasOrganizationRole(AdminRoles.OrganizationAdmin, organization) ||
      // this.hasOrganizationRole(AdminRoles.CollaborationAdmin, organization) ||
      this.isOmniAdmin()
    );
  }

  canUpdateBBB(_organization: OrganizationSubject) {
    return this.isOmniAdmin();
  }

  canUpdateBildungsplattform(_organization: OrganizationSubject) {
    return this.isOmniAdmin();
  }

  canUpdateNextcloud(_organization: OrganizationSubject) {
    return this.isOmniAdmin();
  }

  canUpdateThreema(_organization: OrganizationSubject) {
    return this.isOmniAdmin();
  }

  canUpdateThreemaBroadcast(_organization: OrganizationSubject) {
    return this.isOmniAdmin();
  }

  canUpdateZoom(_organization: OrganizationSubject) {
    return this.isOmniAdmin();
  }

  canUpdateCollaborationGroups(organization?: OrganizationSubject) {
    return (
      (this.isOmniAdmin() ||
        this.isOrganizationAdmin(organization ?? this.organization) ||
        this.hasOrganizationRole(AdminRoles.CollaborationAdmin, organization ?? this.organization) ||
        this.hasOrganizationRole(AdminRoles.GroupAdmin, organization ?? this.organization)) &&
      this.organization.collaboratesWith.length > 0
    );
  }

  canUpdateCollaborationCourses(organization?: OrganizationSubject) {
    return this.canUpdateCollaborationGroups(organization);
  }

  // end LICENSE & COLLABORATION

  // start INSTITUTION
  canListInstitutionGroups(organization: OrganizationSubject) {
    return (
      this.isOmniAdmin() ||
      this.hasOrganizationRole(AdminRoles.OrganizationAdmin, organization) ||
      this.hasOrganizationRole(AdminRoles.GroupAdmin, organization)
    );
  }

  canListInstitutionCourses(organization: OrganizationSubject) {
    return this.canListInstitutionGroups(organization);
  }

  // end INSTITUTION

  // start CLASSES
  canListClasses(organization: OrganizationSubject) {
    return (
      this.isOmniAdmin() ||
      this.hasOrganizationRole(AdminRoles.OrganizationAdmin, organization) ||
      this.hasOrganizationRole(AdminRoles.GroupAdmin, organization)
    );
  }

  // end CLASSES

  // start SUBJECT
  canListSubjects(organization: OrganizationSubject) {
    return this.isOmniAdmin() || this.hasOrganizationRole(AdminRoles.OrganizationAdmin, organization);
  }

  canCreateSubject(organization: OrganizationSubject) {
    return this.canListSubjects(organization);
  }

  canUpdateSubject(organization: OrganizationSubject) {
    return this.canListSubjects(organization);
  }

  canDeleteSubject(_organization: OrganizationSubject) {
    return this.isOmniAdmin();
  }

  // end SUBJECT

  // start SCHOOLYEAR
  canListSchoolyears(organization: OrganizationSubject) {
    return this.isOmniAdmin() || this.hasOrganizationRole(AdminRoles.OrganizationAdmin, organization);
  }

  canCreateSchoolyear(organization: OrganizationSubject) {
    return this.canListSchoolyears(organization);
  }

  canUpdateSchoolyear(organization: OrganizationSubject) {
    return this.canListSchoolyears(organization);
  }

  canDeleteSchoolyear(_organization: OrganizationSubject) {
    return this.isOmniAdmin();
  }

  // end SCHOOLYEAR

  // start ANNOUNCEMENT
  canCreateGroupAnnouncement(group: GroupSubject) {
    return (
      this.isOmniAdmin() || this.hasGroupRole(GroupRoles.Editor, group) || this.hasGroupRole(GroupRoles.Admin, group)
    );
  }

  canCreateCourseAnnouncement(group: GroupSubject) {
    return this.canCreateGroupAnnouncement(group);
  }

  canCreateOrganizationAnnouncment(organization: OrganizationSubject): boolean {
    return this.isOrganizationAdmin(organization) || this.isOmniAdmin();
  }

  // end ANNOUNCEMENT

  // start ASSIGNMENT
  canCreateAssignment(group: GroupSubject) {
    return this.isOmniAdmin() || this.hasGroupRole(GroupRoles.Editor, group);
  }

  canUpdateAssignment(assignment: AssignmentSubject) {
    return (
      this.isOmniAdmin() ||
      this.is({ uuid: assignment.ownerUuid, organization: assignment.organization }) ||
      this.hasGroupRole(GroupRoles.Editor, { uuid: assignment.groupUuid, organization: assignment.organization })
    );
  }

  canDeleteAssignment(assignment: AssignmentSubject) {
    return (
      this.isOmniAdmin() ||
      this.is({ uuid: assignment.ownerUuid, organization: assignment.organization }) ||
      this.hasGroupRole(GroupRoles.Editor, { uuid: assignment.groupUuid, organization: assignment.organization })
    );
  }

  // end ASSIGNMENT

  // start SUBMISSION
  canViewSubmissions(assignment: AssignmentSubject) {
    return (
      this.isOmniAdmin() ||
      this.is({ uuid: assignment.ownerUuid, organization: assignment.organization }) ||
      this.hasGroupRole(GroupRoles.Editor, { uuid: assignment.groupUuid, organization: assignment.organization }) ||
      this.hasGroupRole(GroupRoles.Admin, { uuid: assignment.groupUuid, organization: assignment.organization })
    );
  }

  // end SUBMISSION

  // start ZOOM
  canReadZoomOrganization(organization: OrganizationSubject): boolean {
    return this.isOrganizationAdmin(organization) || this.isOmniAdmin();
  }

  canCreateZoomOrganization(organization: OrganizationSubject): boolean {
    return this.isOrganizationAdmin(organization) || this.isOmniAdmin();
  }

  canUpdateZoomOrganizationSettings(organization: OrganizationSubject): boolean {
    return this.isOrganizationAdmin(organization) || this.isOmniAdmin();
  }

  canUpdateZoomOrganizationCredentials(organization: OrganizationSubject): boolean {
    return this.isOrganizationAdmin(organization) || this.isOmniAdmin();
  }

  canDeleteZoomOrganization(organization: OrganizationSubject): boolean {
    return this.isOrganizationAdmin(organization) || this.isOmniAdmin();
  }

  canReadZoomProfile(profile: ProfileSubject): boolean {
    // TODO allowed if given profile is current profile?
    return this.isOrganizationAdmin(profile.organization) || this.isOmniAdmin();
  }

  canCreateZoomProfile(organization: OrganizationSubject): boolean {
    return this.isOrganizationAdmin(organization) || this.isOmniAdmin();
  }

  canDeleteZoomProfile(profile: ProfileSubject): boolean {
    return this.isOrganizationAdmin(profile.organization) || this.isOmniAdmin();
  }

  canListZoomProfiles(organization: OrganizationSubject): boolean {
    return this.isOrganizationAdmin(organization) || this.isOmniAdmin();
  }

  canCreateZoomMeeting(organization: OrganizationSubject): boolean {
    return this.isOrganizationAdmin(organization) || this.isOmniAdmin();
  }

  canReadZoomMeeting(zoomMeeting: ZoomMeetingSubject): boolean {
    // TODO allowed if given profile is meeting user?
    return this.isOrganizationAdmin({ uuid: zoomMeeting.organizationUuid }) || this.isOmniAdmin();
  }

  canListZoomMeetings(organization: OrganizationSubject): boolean {
    return this.isOrganizationAdmin(organization) || this.isOmniAdmin();
  }

  // end ZOOM

  // start PROFILE
  canListProfiles(organization: OrganizationSubject) {
    return (
      this.hasOrganizationRole(AdminRoles.ProfileAdmin, organization) ||
      this.isOrganizationAdmin(organization) ||
      this.isOmniAdmin()
    );
  }

  // end PROFILE

  // start FILE
  canUploadFile(_organization: OrganizationSubject): boolean {
    // TODO implement
    warnAboutInsufficientPermissionChecks();
    return true;
  }

  canReadFileEntry(fileEntry: FileEntrySubject): boolean {
    if (this.isOmniAdmin() || this.isOrganizationAdmin({ uuid: fileEntry.organization.uuid })) {
      return true;
    }

    if (fileEntry.organization.uuid !== this.profile.organization.uuid) {
      return false;
    }

    if (fileEntry.owner.uuid === this.profile.uuid) {
      return true;
    }

    // TODO implement more checks. A file can be read if it is made public to the current profile somehow
    // For now, allow access to all profiles in the same organization
    warnAboutInsufficientPermissionChecks();

    return true;
  }

  canDownloadFile(fileEntry: FileEntrySubject): boolean {
    return this.canReadFileEntry(fileEntry);
  }

  // end FILE

  // start GROUP
  isGroupAdmin(group: GroupSubject) {
    return this.hasGroupRole(GroupRoles.Admin, group);
  }

  isGroupEditor(group: GroupSubject) {
    return this.hasGroupRole(GroupRoles.Editor, group);
  }

  isGroupViewer(group: GroupSubject) {
    return this.hasGroupRole(GroupRoles.Viewer, group);
  }

  isGroupMember(group: GroupSubject) {
    return this.isGroupEditor(group) || this.isGroupViewer(group);
  }

  canViewGroup(group: GroupSubject) {
    return (
      this.isOmniAdmin() ||
      this.isOrganizationAdmin(this.organization) ||
      this.isGroupAdmin(group) ||
      this.isGroupMember(group)
    );
  }

  canCreateUserInGroup(group: GroupSubject) {
    return this.isOmniAdmin() || this.isGroupAdmin(group);
  }

  canCreateCollaborationGroups() {
    return (
      this.isOmniAdmin() ||
      this.isOrganizationAdmin(this.organization) ||
      this.hasOrganizationRole(AdminRoles.GroupAdmin, this.organization)
    );
  }

  canCreateBpGroups() {
    return (
      this.isOmniAdmin() ||
      this.isOrganizationAdmin(this.organization) ||
      this.hasOrganizationRole(AdminRoles.GroupAdmin, this.organization) ||
      this.hasOrganizationRole(ProfileRoles.Teacher, this.organization)
    );
  }

  canEditBpGroup(group: GroupSubject) {
    return (
      this.isOmniAdmin() ||
      this.hasOrganizationRole(AdminRoles.GroupAdmin, this.organization) ||
      this.isGroupAdmin(group)
    );
  }

  canDeleteBpGroups() {
    return this.isOmniAdmin() || this.isOrganizationAdmin(this.organization);
  }

  canCreateBpGroupMaterial(group: GroupSubject) {
    return this.canEditBpGroup(group) || this.isGroupEditor(group);
  }

  canCreateBpGroupNotifications(group: GroupSubject) {
    return this.canCreateBpGroups() || this.canEditBpGroup(group) || this.isGroupEditor(group);
  }

  canCreateBpGroupEvents(group: GroupSubject) {
    return this.canEditBpGroup(group) || this.isGroupEditor(group);
  }

  canCreateBpCourseEvents(group: GroupSubject) {
    return this.canEditBpGroup(group) || this.isGroupEditor(group);
  }

  canChangeBpGroupPermissions(group: GroupSubject) {
    return (
      this.isGroupAdmin(group) ||
      this.hasOrganizationRole(AdminRoles.GroupAdmin, group.organization) ||
      this.isOmniAdmin()
    );
  }

  // end GROUP

  // start COURSE
  canViewCourse(group: GroupSubject) {
    return this.canViewGroup(group);
  }

  canCreateUserInCourse(group: GroupSubject) {
    return this.isOmniAdmin() || this.isGroupAdmin(group) || this.isGroupEditor(group);
  }

  canDownloadClasslist(group: GroupSubject): boolean {
    return this.isOmniAdmin() || this.isGroupEditor(group);
  }

  canCreateCourse() {
    return (
      this.isOmniAdmin() ||
      this.hasOrganizationRole(AdminRoles.GroupAdmin, this.organization) ||
      this.hasOrganizationRole(ProfileRoles.Teacher, this.organization)
    );
  }

  canDeleteCourse() {
    return this.isOmniAdmin() || this.hasOrganizationRole(AdminRoles.GroupAdmin, this.organization);
  }

  canUpdateCourse(group: GroupSubject) {
    return (
      this.isOmniAdmin() ||
      this.hasOrganizationRole(AdminRoles.GroupAdmin, this.organization) ||
      this.isGroupAdmin(group)
    );
  }

  isCourseEditor(group: GroupSubject) {
    return this.hasGroupRole(GroupRoles.Editor, group);
  }

  canUpdateGroupSettings(group: GroupSubject) {
    return (
      this.isOmniAdmin() ||
      this.hasOrganizationRole(AdminRoles.GroupAdmin, this.organization) ||
      this.isGroupAdmin(group)
    );
  }

  canCreateWorkmaterial(group: GroupSubject) {
    return (
      this.isOmniAdmin() ||
      this.hasOrganizationRole(AdminRoles.GroupAdmin, this.organization) ||
      this.isGroupEditor(group) ||
      this.isGroupAdmin(group)
    );
  }

  canDeleteWorkmaterial(group: GroupSubject, workmaterial: WorkmaterialSubject) {
    return (
      this.isOmniAdmin() ||
      this.hasOrganizationRole(AdminRoles.GroupAdmin, this.organization) ||
      this.isGroupAdmin(group) ||
      this.is(workmaterial.owner) ||
      this.isGroupEditor(group)
    );
  }

  canUpdateWorkmaterial(group: GroupSubject, workmaterial: WorkmaterialSubject) {
    return this.isOmniAdmin() || this.is(workmaterial.owner) || this.isGroupEditor(group);
  }

  // end COURSE

  // start MATRIX
  canDirectMessageWithInGroup(group: GroupSubject, target: ProfileRoleSubject): boolean {
    return (
      this.isOmniAdmin() ||
      this.isGroupAdmin(group) ||
      this.isOrganizationAdmin(this.organization) ||
      !this.hasOrganizationRole(ProfileRoles.Student, this.organization) ||
      (this.hasOrganizationRole(ProfileRoles.Student, this.organization) &&
        (this.allowStudentDirectMessages || target.role !== ProfileRoles.Student))
    );
  }

  canDirectMessageWithinCourse(group: GroupSubject, target: ProfileRoleSubject): boolean {
    return this.canDirectMessageWithInGroup(group, target);
  }

  // end MATRIX

  // start SETTINGS
  canViewSettings(organization: OrganizationSubject) {
    return (
      this.isOmniAdmin() ||
      this.isOrganizationAdmin(organization) ||
      this.hasOrganizationRole(AdminRoles.CollaborationAdmin, organization) ||
      this.hasOrganizationRole(AdminRoles.GroupAdmin, organization) ||
      this.hasOrganizationRole(AdminRoles.ProfileAdmin, organization)
    );
  }

  // end SETTINGS

  // start CLASSBOOK
  canViewClassbook(organization: OrganizationSubject, profile: ProfileClaim, children: ChildProfilesClaim) {
    return (
      this.isOmniAdmin() ||
      this.isOrganizationAdmin(organization) ||
      this.canViewClassbookCoursesAndClasses(organization) ||
      this.canCreateAbsenceForChildren(organization, children) ||
      this.canCreateAbsenceForSelf(organization, profile)
    );
  }

  canViewClassbookCoursesAndClasses(organization: OrganizationSubject) {
    return (
      this.isOmniAdmin() ||
      this.isOrganizationAdmin(organization) ||
      this.hasOrganizationRole(ProfileRoles.Teacher, organization)
    );
  }

  canViewClassbookAdministration(organization: OrganizationSubject) {
    return this.isOmniAdmin() || this.isOrganizationAdmin(organization);
  }

  canCreateAbsenceForChildren(organization: OrganizationSubject, children: ChildProfilesClaim) {
    return (
      this.isOmniAdmin() ||
      this.isOrganizationAdmin(organization) ||
      (this.hasOrganizationRole(ProfileRoles.Parent, organization) && children.length > 0)
    );
  }

  canCreateAbsenceForSelf(organization: OrganizationSubject, profile: ProfileClaim) {
    if (!profile.birthday) return false;

    const today = dayjs().startOf('day');
    const birthday = dayjs(profile.birthday).startOf('day');
    const diff = today.diff(birthday);
    const isLegalAge = diff / 31536000000 >= 18;

    return (
      this.isOmniAdmin() ||
      this.isOrganizationAdmin(organization) ||
      (this.hasOrganizationRole(ProfileRoles.Student, organization) && isLegalAge)
    );
  }

  // end CLASSBOOK

  // start MEETINGS
  canViewMeetings(organization: OrganizationSubject) {
    return this.isOmniAdmin() || this.isOrganizationAdmin(organization);
  }

  // end MEETINGS

  // start TEMPLATES
  canViewTemplates() {
    return this.isOmniAdmin();
  }

  // end TEMPLATES

  // start MEDIA
  canViewMediaLibrary() {
    return this.isOmniAdmin();
  }

  // end MEDIA

  // start ACCOUNTS
  canViewAccounts() {
    return this.isOmniAdmin();
  }

  // end ACCOUNTS

  // start ADMIN
  canViewAdmin() {
    return this.isOmniAdmin();
  }

  // end ADMIN
}

function warnAboutInsufficientPermissionChecks() {
  console.log('FIXME: Insufficient permission checks in %s', __filename);
}
